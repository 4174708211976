var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-card",
    { staticClass: "md-card-profile" },
    [
      _c("div", { staticClass: "md-card-avatar" }, [
        _c("img", { staticClass: "img", attrs: { src: _vm.cardUserImage } }),
      ]),
      _c(
        "md-card-content",
        [
          _c("h6", { staticClass: "category text-gray" }, [
            _vm._v("CEO / Co-Founder"),
          ]),
          _c("h4", { staticClass: "card-title" }, [_vm._v("Alec Thompson")]),
          _c("p", { staticClass: "card-description" }, [
            _vm._v(
              " Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is... "
            ),
          ]),
          _c(
            "md-button",
            {
              staticClass: "md-round",
              class: _vm.getColorButton(_vm.buttonColor),
            },
            [_vm._v("Follow")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }